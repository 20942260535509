<template>
  <v-app>
    <v-overlay z-index="205" :value="isProcessing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-app-bar app color="#af955c" dark flat height="60">
      <div class="d-flex align-center">
        <v-btn class="ml-n2" width="40" height="40" v-if="$vuetify.breakpoint.smAndDown && ($router.currentRoute.name === 'AccountView' || $router.currentRoute.name === 'StaffAccountView')" icon dark @click.stop="isDrawerVisible = true">
          <v-icon class="shrink" transition="scale-transition" size="25">
            mdi-menu
          </v-icon>
        </v-btn>
        <v-img v-if="$router.currentRoute.name != 'AccountView' && $router.currentRoute.name != 'HowTo' && $router.currentRoute.name != 'StaffAccountView'"
          alt="Золотое сечение"
          class="shrink mr-4"
          max-height="48"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://zsfond.ru/"
        target="_blank"
        text
      >
        <span class="mr-2">Перейти на сайт</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$router.currentRoute.name === 'StaffAccountView'"
      v-model="isDrawerVisible"
      app
      mobile-breakpoint="sm"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
      width="250"
    >
      <v-list-item :class="$vuetify.breakpoint.mdAndUp ? 'with-background theme--dark pt-1 px-2' : 'px-2'">
        <v-list-item-avatar tile>
          <v-img contain src="./assets/only-logo-white.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-button">Личный кабинет</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
      
      <v-list dense>
        <v-list-item link to="/staff/camps">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/staff/camps' ? 'mdi-briefcase' : 'mdi-briefcase-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Мои смены</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/staff/profile">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/staff/profile' ? 'mdi-account' : 'mdi-account-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Профиль</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/staff/documents">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/staff/documents' ? 'mdi-file-document' : 'mdi-file-document-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Документы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="mt-3" @click="signOut">
          <v-list-item-icon>
            <v-icon color="#e31e25">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Выйти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
    
    <v-navigation-drawer
      v-if="$router.currentRoute.name === 'AccountView'"
      v-model="isDrawerVisible"
      app
      mobile-breakpoint="sm"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
      width="250"
    >
      <v-list-item :class="$vuetify.breakpoint.mdAndUp ? 'with-background theme--dark pt-1 px-2' : 'px-2'">
        <v-list-item-avatar tile>
          <v-img contain src="./assets/only-logo-white.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-button">Личный кабинет</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>

      <v-list dense>
        <v-list-item link to="/orders">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/orders' ? 'mdi-notebook' : 'mdi-notebook-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Мои заявки</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/courses">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/courses' ? 'mdi-book-open-page-variant' : 'mdi-book-open-page-variant-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Мои курсы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/profile">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/profile' ? 'mdi-account' : 'mdi-account-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Профиль</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/education">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/education' ? 'mdi-school' : 'mdi-school-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Образование</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/achievements">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/achievements' ? 'mdi-trophy' : 'mdi-trophy-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Достижения</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/documents">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{$route.path === '/documents' ? 'mdi-file-document' : 'mdi-file-document-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Документы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="howToDataSections.length > 0" link :to="{name: 'HowTo', params: { prev: 'AccountView'}}">
          <v-list-item-icon>
            <v-icon color="#00AEBA">{{'mdi-account-question-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Частые вопросы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="mt-3" @click="signOut">
          <v-list-item-icon>
            <v-icon color="#e31e25">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">Выйти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
    <v-main>
      <keep-alive include="RegistrationView">
        <router-view
         v-on:set-processing="setProcessing"
         v-on:increment-cache-key="incrementKey"
         :key="cacheRegistrationPage">
        </router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data: () => ({
    cacheRegistrationPage: 0,  
    isDrawerVisible: true,
    isProcessing: false,
    
    howToDataSections: [],
  }),
  methods: {
    incrementKey() {      
      this.cacheRegistrationPage += 1;
    },
    setProcessing(state) {
      this.isProcessing = state;
    },
    signOut() {
      window.location.href = "/Auth/SignOut";
    },
    async getHowToData() {
      this.setProcessing(true);
      try {
        let response = await axios.get("/api/getHowToData", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (response.data.success === false)
          throw new Error("Ошибка загрузки данных страницы помощи");
        this.howToDataSections = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.setProcessing(false);
      }
    },
  },
  created() {
    this.getHowToData();
  },
};
</script>

<style lang="scss">
  html {
    overflow-y: auto !important;
  }
  .v-main__wrap {
    background-repeat: initial;
    background-image: url(assets/pixel.png);
  }
  .v-list-item.with-background {
    background: #af955c;
  }
  .v-navigation-drawer {
    &__border {
      top: 60px !important;
    }
  }
  
  .text-body-1 {
    font-size: 1.2rem !important;
    font-weight: 300 !important;
  }
</style>
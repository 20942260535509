export default {
    firstName: null,
    lastName: null,
    middleName: null,
    birthdayDate: null,
    gender: null,
    login: null,
    password: null,
    passwordRepeated: null,
    education_levelid: null,
    qualification_categ: null, 
    contacts: {
      address_reg: null,
      address_reg_house: null,
      address_reg_building: null,
      address_reg_flat: null,
      email: null,
      phone: null,
    },
    documents: {
      snils: null,
    },
  }
<template>
  <div v-if="studentInfo" class="mb-4" v-cloak>
    <p class="text-h5 font-weight-light mt-3">Мое образование</p>
    <v-divider></v-divider>
    <v-row v-if="isGeneralEditing || studentInfo.education.general.name" class="mt-7" justify="center" align="center">
      <v-card 
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'" 
        flat 
        tile 
        outlined 
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-school-outline</v-icon>
            <span>Общее образование</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :color="isGeneralEditing ? '#e31e25' : '#7690c9'" text dark x-small :disabled="isProfessionalEditing" @click="generalEditButton">
            <v-icon left dark>{{isGeneralEditing ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
            {{isGeneralEditing ? 'Отмена' : 'Редактировать'}}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div v-if="!isGeneralEditing" class="pl-4 my-4">
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Наименование учреждения
            </span>
            <p class="text-body-1">
              {{studentInfo.education.general.name ? studentInfo.education.general.name : 'Не указано'}}
            </p>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Тип учреждения:
            </span>
            <span class="text-body-1">
              {{studentInfo.education.general.typeText ? studentInfo.education.general.typeText : 'Не указан'}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Год обучения:
            </span>
            <span class="text-body-1">
              {{studentInfo.education.general.grade ? studentInfo.education.general.grade : 'Не указан'}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Иностранный язык:
            </span>
            <span class="text-body-1">
              {{studentInfo.education.general.languageText ? studentInfo.education.general.languageText : 'Не указан'}}
            </span>
          </div>
          <div v-if="studentInfo.education.general.remote">
            <span class="text-body-1 font-weight-half-medium">
              Примечание:
            </span>
            <span class="text-body-1">
              Самостоятельное обучение
            </span>
          </div>
        </div>
        <v-form v-else class="mx-5" v-model="isGeneralValid">
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                v-model="studentInfoForEdit.education.general.name"
                label="Наименование учреждения"
                hint="Введите название учреждения"
                color="#af955c"
                dense
                :rules="[rules.required]"
                required
                :error="!studentInfoForEdit.education.general.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="6" md="6" lg="2" xl="2">
              <v-select
                v-model="studentInfoForEdit.education.general.type"
                label="Тип"
                hint="Укажите тип учреждения"
                color="#af955c"
                dense
                :items="generalEducationTypes"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                :error="!studentInfoForEdit.education.general.type"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-select
                v-model="studentInfoForEdit.education.general.language"
                label="Иностранный язык"
                hint="Укажите ин. язык"
                color="#af955c"
                dense
                item-text="name"
                item-value="id"
                :items="foreignLanguages"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="2" xl="2">
              <v-select
                v-model="studentInfoForEdit.education.general.grade"
                label="Класс"
                hint="Укажите класс"
                color="#af955c"
                dense
                :items="grades"
                :rules="[rules.required]"
                required
                :error="!studentInfoForEdit.education.general.grade"
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-checkbox
                v-model="studentInfoForEdit.education.general.remote"
                label="Самостоятельное обучение"
                color="#af955c"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-3 mb-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isGeneralValid" 
              :dark="isGeneralValid"
              color="#af955c"
              @click="save"
            >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>

    <v-row v-if="isProfessionalEditing || studentInfo.education.professional.name" class="mt-8" justify="center" align="center">
      <v-card 
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'" 
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
        flat 
        tile 
        outlined 
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-bank</v-icon>
            <span>Профессиональное образование</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :color="isProfessionalEditing ? '#e31e25' : '#7690c9'" text dark x-small :disabled="isGeneralEditing" @click="professionalEditButton">
            <v-icon left dark>{{isProfessionalEditing ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
            {{isProfessionalEditing ? 'Отмена' : 'Редактировать'}}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div v-if="!isProfessionalEditing" class="pl-4 my-4">
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Наименование учреждения
            </span>
            <p class="text-body-1">
              {{studentInfo.education.professional.name ? studentInfo.education.professional.name : 'Не указано'}}
            </p>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Тип учреждения:
            </span>
            <span class="text-body-1">
              {{studentInfo.education.professional.typeText ? studentInfo.education.professional.typeText : 'Не указан'}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Год обучения:
            </span>
            <span class="text-body-1">
              {{studentInfo.education.professional.grade ? studentInfo.education.professional.grade : 'Не указан'}}
            </span>
          </div>
        </div>
        <v-form v-else class="mx-5" v-model="isProfessionalValid">
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                v-model="studentInfoForEdit.education.professional.name"
                label="Наименование учреждения"
                hint="Введите название учреждения"
                color="#af955c"
                dense
                :rules="[rules.required]"
                required
                :error="!studentInfoForEdit.education.professional.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start" align="center">
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-select
                v-model="studentInfoForEdit.education.professional.type"
                label="Тип"
                hint="Укажите тип учреждения"
                color="#af955c"
                dense
                :items="professionalEducationTypes"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                required
                :error="!studentInfoForEdit.education.professional.type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2" xl="4">
              <v-select
                v-model="studentInfoForEdit.education.professional.grade"
                label="Год"
                hint="Укажите год обучения"
                color="#af955c"
                dense
                :items="grades"
                :rules="[rules.required]"
                required
                :error="!studentInfoForEdit.education.professional.grade"
              ></v-select>
            </v-col>
          </v-row>
          <v-row 
            v-if="studentInfo.education.professional.name" 
            class="mt-4" 
            justify="center" 
            align="center"
          >
            <v-btn
              color="#e31e25"
              class="ma-2"
              text
              dark
              small
              @click="deleteProfessionalButton"
            >
              <v-icon left dark>
                mdi-window-close
              </v-icon>
              Удалить проф. образование
            </v-btn>
          </v-row>
          <v-row class="mt-3 mb-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isProfessionalValid" 
              :dark="isProfessionalValid"
              color="#af955c"
              @click="save"
            >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-row 
      v-if="!studentInfo.education.general.name && !isGeneralEditing && !isProfessionalEditing" 
      class="mt-4" 
      justify="center" 
      align="center"
    >
      <v-btn
        color="#7690c9"
        class="ma-2"
        text
        dark
        @click="addGeneralButton"
      >
        <v-icon left dark>
          mdi-plus
        </v-icon>
        Добавить общее образование
      </v-btn>
    </v-row>
    <v-row 
      v-if="!studentInfo.education.professional.name && !isGeneralEditing && !isProfessionalEditing" 
      class="mt-4" 
      justify="center" 
      align="center"
    >
      <v-btn
        color="#7690c9"
        class="ma-2"
        text
        dark
        @click="addProfessionalButton"
      >
        <v-icon left dark>
          mdi-plus
        </v-icon>
        Добавить проф. образование
      </v-btn>
    </v-row>
  </div>
</template>

<script>
  import axios from 'axios';
  import gradesEnum from '../model/grades';

  export default {
    name: "Education",
    props: {
      studentInfo: Object,
      rules: Object,
      parseDate: Function
    },
    data: () => ({
      studentInfoForEdit: {},
      isGeneralEditing: false,
      isProfessionalEditing: false,
      isGeneralValid: false,
      isProfessionalValid: false,

      generalEducationTypes: [],
      professionalEducationTypes: [],
      grades: gradesEnum,
      foreignLanguages: [],
    }),
    computed: {
      studentInfoStringify() {
        return JSON.stringify(this.studentInfo);
      }
    },
    methods: {
      async setStudentInfoForEdit() {
        if(this.studentInfo) {
          this.studentInfoForEdit = JSON.parse(JSON.stringify(this.studentInfo));
          await this.$nextTick();
          this.studentInfoForEdit.birthdayDate = this.parseDate(this.studentInfo.birthdayDateFormated);
        }
      },
      save() {
        this.$emit('save', this.studentInfoForEdit, 'education');
      },
      async getDictionariesData() {
        this.$emit('set-processing', true);
        
        await this.getGeneralEducationTypes();
        await this.getProfessionalEducationTypes();
        await this.getForeignLanguages();

        this.$emit('set-processing', false);

      },
      async getForeignLanguages() {
        try {
          let response = await axios.get("/api/getLanguagesList", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.foreignLanguages = response.data;
        } 
        catch (error) {
          this.foreignLanguages = [];
        }
      },
      async getGeneralEducationTypes() {
        try {
          let response = await axios.get(
            "/api/getEducationTypes?kind=" + encodeURIComponent("Общее"),
            {
              headers: {
                RequestVerificationToken: this.token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          this.generalEducationTypes = response.data;
        } 
        catch (error) {
          this.generalEducationTypes = [];
        }
      },
      async getProfessionalEducationTypes() {
        try {
          let response = await axios.get(
            "/api/getEducationTypes?kind=" +
              encodeURIComponent("Профессиональное"),
            {
              headers: {
                RequestVerificationToken: this.token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          this.professionalEducationTypes = response.data;
        } 
        catch (error) {
          this.professionalEducationTypes = [];
        }
      },
      generalEditButton() {
        if (!this.isGeneralEditing) {
          this.isGeneralEditing = true;
        } 
        else {
          this.isGeneralEditing = false;
          this.setStudentInfoForEdit();
        }
      },
      professionalEditButton() {
        if (!this.isProfessionalEditing) {
          this.isProfessionalEditing = true;
        } 
        else {
          this.isProfessionalEditing = false;
          this.setStudentInfoForEdit();
        }
      },
      addGeneralButton() {
        this.isGeneralEditing = true;
      },
      addProfessionalButton() {
        this.isProfessionalEditing = true;
      },
      async deleteGeneralButton() {
        this.isGeneralEditing = false;

        this.studentInfoForEdit.education.general.name = null;
        this.studentInfoForEdit.education.general.type = null;
        this.studentInfoForEdit.education.general.grade = null;
        this.studentInfoForEdit.education.general.language = null;
        this.studentInfoForEdit.education.general.remote = false;

        await this.$nextTick();
        this.save();
      },
      async deleteProfessionalButton() {
        this.isProfessionalEditing = false;

        this.studentInfoForEdit.education.professional.name = null;
        this.studentInfoForEdit.education.professional.type = null;
        this.studentInfoForEdit.education.professional.grade = null;

        await this.$nextTick();
        this.save();
      }
    },
    created() {
      this.getDictionariesData();
      this.setStudentInfoForEdit();
    },
    watch: {
      studentInfoStringify: {
        handler(val, oldVal) {
          this.setStudentInfoForEdit();
        },
      },
    }
  };
</script>
<template>
  <v-form class="mx-5" v-model="isDocumentValid">
    <v-row class="mt-4" justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-select
          v-model="documentForEdit.doc_typeid"
          label="Тип документа"
          hint="Укажите тип документа"
          color="#af955c"
          dense
          :items="documentTypes"
          :disabled="isLoading"
          item-text="name"
          item-value="doc_typeid"
          :rules="[rules.required]"
          :error="!documentForEdit.doc_typeid"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="documentForEdit.doc_typeid" class="mt-4" justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          v-model="documentForEdit.name"
          label="Реквизиты документа"
          hint="Укажите реквизиты документа"
          color="#af955c"
          dense
          required
          :rules="[rules.required]"
          :error="!documentForEdit.name"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <attachment :attach="attachment"> </attachment>
    
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-file-input
          show-size
          clearable
          counter
          color="#af955c"
          :accept="rules.staffValidFileExtentions.join(', ')"
          dense
          label="Вложение"
          :placeholder="
            !documentForEdit.attach && document.attach ? document.attach.name : '' "
          :rules="[rules.file, rules.staffFileFormat]"
          v-on:change="onFileChanged"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row v-if="!isNew" class="mt-4" justify="center" align="center">
      <v-btn
        v-if="!isNew"
        color="#e31e25"
        class="ma-2"
        text
        dark
        small
        @click="deleteDocument"
      >
        <v-icon left dark> mdi-window-close </v-icon>
        Удалить документ
      </v-btn>
    </v-row>
    <v-row class="pt-3 pb-1" justify="center" align="center">
      <v-btn
        color="#af955c"
        text
        :disabled="!isDocumentValid"
        :dark="isDocumentValid"
        @click="saveDocument"
      >
        <v-icon left dark>mdi-content-save-outline</v-icon>
        Сохранить
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import AttachmentVue from "./Attachment.vue";

export default {
  name: "DocumentForm",
  props: {
    isNew: Boolean,
    availableDocumentTypes: Array,
    document: Object,
    rules: Object,

  },
  data() {
    return {
      documentTypes: [],
      documentForEdit: {
        doc_typeid: null,
        name: null,
        data: {
          field1: null,
          field2: null,
        },
      },
      attachment: null,
      isDocumentValid: false,
      token: null,
      isLoading: false,
    }
  },
  components: {
    attachment: AttachmentVue,
  },
  computed: {
    documentStringify() {
      return JSON.stringify(this.document);
    },
    documentForEditStringify() {
      return JSON.stringify(this.documentForEdit);
    },
  },
  methods: {
    async getAttachment() {
      if (this.documentForEdit.attach) {
        const file = this.documentForEdit.attach;
        let bytes = await this.convertToBytes(file).catch((e) => Error(e));
        if (bytes instanceof Error) {
          this.$emit(
            "show-snackbar",
            false,
            "Ошибка загрузки файла, пожалуйста, попробуйте еще раз."
          );
          return;
        }
        let attach = {
          name: file.name,
          data: bytes.replace(/^data:.+;base64,/, ""),
        };
        this.attachment = attach;
      } else {
        this.attachment = this.document.attach;
      }
    },
    async getDocTypes() {
      this.isLoading = true;
      this.$emit('set-loading', true);
      try {
        let response = await axios.get("/api/getDocumentTypes?forStaff=true", {
          headers: {
            "RequestVerificationToken": this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.documentTypes = response.data;
        this.documentTypes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }
      catch (error) {
        this.documentTypes = [];
      }  
      finally {
        this.isLoading = false;
        this.$emit('set-loading', false);
      }   
    },
    async onFileChanged(file) {
      this.documentForEdit.attach = file;
      await this.$nextTick();
      this.getAttachment();
    },
    convertToBytes(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    setDocumentForEdit() {
      if (this.document) {
        this.documentForEdit = JSON.parse(JSON.stringify(this.document));
        this.documentForEdit.attach = null;
      }
    },
    saveDocument() {
      this.$emit("save-document", this.documentForEdit);
    },
    deleteDocument() {
      this.$emit("delete-document", this.document.id);
    },
    getToken() {
      let tokenElements = document.getElementsByName("__RequestVerificationToken");
      if(tokenElements && tokenElements.length > 0)
        this.token = tokenElements[0].value;
    },
  },
  watch: {
    documentStringify: {
      handler(val, oldVal) {
        this.setDocumentForEdit();
      },
    },
    documentForEditStringify: {
      handler(val, oldVal) {
        // const newDocument = JSON.parse(val);
        // const oldocument = JSON.parse(oldVal);
      },
    },
  },
  created() {
    this.getToken();
    this.setDocumentForEdit();
    this.getAttachment();
    this.getDocTypes();
  },
  mounted(){
  },
};
</script>
<style scoped></style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import AccountView from '../views/MainView.vue';
import StaffAccountView from '../views/staff/MainView.vue'
import HowToView from '../views/HowTo.vue'
import QualsAccountView from '../views/quals/MainView.vue'

Vue.use(VueRouter)

const studentAlias = ['/orders', '/profile', '/education', '/achievements', '/documents', '/courses'];
const staffAlias = ['/staff/profile', '/staff/documents', '/staff/camps'];
const qualsAlias = ['/quals/profile', '/quals/documents', 'quals/camps'];

const routes = [
  {
    path: '/',
    beforeEnter(to, from, next) {
      const currentURL = window.location.href
      const isOAuthRedirect = currentURL.includes('oauth2callback.php')
      if (isOAuthRedirect) { 
        return
      }
      const userRole = Vue.prototype.$userRole
      if (to.path === '/' && userRole === 'Ученик') {
        next('/orders')
      }
      else if ((to.path === '/' || studentAlias.includes(to.path)) && userRole === 'Персонал') {
        next('/staff')
      }
      else if ((to.path === '/' || studentAlias.includes(to.path)) && userRole === 'Персонал') {
        next('/quals')
      }
      else if (userRole === 'Оператор') {
        window.location.href = '/home'
      }
      else {
        next()
      }
    },
    name: 'AccountView',
    component: AccountView,
    alias: studentAlias,
  },
  {
    path: '/staff',
    beforeEnter(to, from, next) {
      const userRole = Vue.prototype.$userRole
      if (to.path === '/staff' && userRole === 'Персонал') {
        next('/staff/profile')
      }
      else if ((to.path === '/staff' || staffAlias.includes(to.path)) && (userRole === 'Квалификация. Слушатель' || userRole === 'Ученик')) {
        next('/')
      }
      else if (userRole === 'Оператор') {
        window.location.href = '/home'
      }
      else if (userRole === 'Несколько ролей') {
        window.location.href = "/Auth/SignOut";
      }
      else {
        next()
      }
    },
    name: 'StaffAccountView',
    component: StaffAccountView,
    alias: staffAlias,
  },
  {
    path: '/quals',
    beforeEnter(to, from, next) {
      const userRole = Vue.prototype.$userRole
      if (to.path === '/quals' && userRole === 'Квалификация. Слушатель') {
        next('/quals/profile')
      }
      else if ((to.path === '/quals' || qualsAlias.includes(to.path)) && (userRole === 'Персонал' || userRole === 'Ученик') ) {
        next('/')
      }
      else if (userRole === 'Оператор') {
        window.location.href = '/home'
      }
      else if (userRole === 'Несколько ролей') {
        window.location.href = "/Auth/SignOut";
      }
      else {
        next()
      }
    },
    name: 'QualsAccountView',
    component: QualsAccountView,
    alias: qualsAlias,
  },
  {
    path: '/howto/:id?',
    name: 'HowTo',
    props: true,
    component: HowToView,
  },
  {
    path: '/staff/registration',
    name: 'StaffRegistration',
    component: () => import('../views/staff/RegistrationView.vue')
  },
  {
    path: '/quals/registration',
    name: 'QualsRegistration',
    component: () => import('../views/quals/RegistrationView.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/RegistrationView.vue')
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  { 
    path: "*", 
    component: () => import('../views/PageNotFound.vue') 
  }
];

const router = new VueRouter({
  //mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  await Vue.prototype.$getUserAuthenticate();
  let isAuthenticated = Vue.prototype.$isAuthenticated;
  if (Vue.prototype.$userRole === 'Несколько ролей'){
    window.location.href = "/Auth/SignOut";
  }
  if (!isAuthenticated && to.path !== '/signin' && to.name !== 'Registration' && to.name !== 'HowTo' && to.path !== '/staff/registration' && to.path !== '/quals/registration') {
    next({
      path: '/signin',
      query: {
        ReturnUrl: to.fullPath,
      }
    });
  }
  else if (isAuthenticated && (to.path === '/signin' || to.path === '/staff/registration' || to.name === 'Registration')) {
    next('/orders');
  }
  // else if (!isAuthenticated && to.path !== '/quals' && to.name !== 'Registration' && to.name !== 'HowTo' && to.path !== '/quals/registration') {
  //   next({
  //     path: '/quals',
  //     query: {
  //       ReturnUrl: to.fullPath,
  //     }
  //   });
  // }
  else if (isAuthenticated && (to.path === '/signin' || to.path === '/quals/registration' || to.name === 'Registration')) {
    next('/orders');
  }
   else next();
});

export default router

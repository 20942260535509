<template>
  <v-container>
    <keep-alive>
      <orders
        ref="orders"
        v-if ="$route.path === '/orders'"
        :rules="rules"
        :formatDate="formatDate"
        :parseDate="parseDate"
        :orders="orders"
        :isEvent="isEvent"
        :achievements="achievements"
        :documents="documents"
        :ordersTableLoading="ordersTableLoading"
        v-on:set-processing="setProcessing"
        v-on:add-achievement="openNewAchievementDialog"
        v-on:change-order-state="changeOrderState"
        v-on:save-new-order="saveNewOrder"
        v-on:delete-order="deleteOrder"
        v-on:select-achievement="selectOrderAchievement"
        v-on:remove-achievement="removeOrderAchievement"
        v-on:load-orders="getOrders"
        v-on:add-document="openNewDocumentDialog"
        v-on:select-document="selectOrderDocument"
        v-on:remove-document="removeOrderDocument"
      ></orders>
      <courses
        ref="courses"
        :formatDate="formatDate"
        :parseDate="parseDate"
        :orders="orders"
        v-if ="$route.path === '/courses'"
        v-on:set-processing="setProcessing">
      </courses>
      <profile
        ref="profile"
        v-if ="$route.path === '/profile'" 
        :studentInfo="studentInfo"
        :rules="rules"
        :formatDate="formatDate"
        :parseDate="parseDate"
        v-on:set-processing="setProcessing"
        v-on:save="saveProfile"
        v-on:change-password="changePassword"
      ></profile>
      <education 
        ref="education" 
        v-if ="$route.path === '/education'"
        :studentInfo="studentInfo"
        :rules="rules"
        :parseDate="parseDate"
        v-on:set-processing="setProcessing"
        v-on:save="saveProfile"
      ></education>
      <achievements
        ref="achievements"
        v-if ="$route.path === '/achievements'" 
        :rules="rules"
        :achievements="achievements"
        :isEvent="isEvent"
        v-on:set-processing="setProcessing"
        v-on:save="saveAchievements"
        v-on:add-achievement="openNewAchievementDialog"
        v-on:delete-achievement="deleteAchievement"
        v-on:show-snackbar="showSnackbar"
        v-on:get-achievements="getAchievements"
      ></achievements>
      <documents
        ref="documents"
        :documents="documents"
        :rules="rules"
        v-if ="$route.path === '/documents'" 
        v-on:set-processing="setProcessing"
        v-on:save="saveDocuments"
        v-on:add-document="openNewDocumentDialog"
        v-on:delete-document="deleteDocument"
        v-on:show-snackbar="showSnackbar"
        v-on:get-documents="getDocuments"
      ></documents>
    </keep-alive>

    <v-snackbar v-model="resultSnackbar"
      class="text-center"
      vertical 
      multi-line 
      timeout="7000"
      :color="isResultSuccess ? '#af955c' : ''"
      :outlined="isResultSuccess"
    >
      <span class="text-center" v-html="resultText"></span>
      <template v-slot:action="{ attrs }">
        <v-btn :color="isResultSuccess ? '#af955c' : '#e31e25'" text v-bind="attrs" @click="() => {resultSnackbar = false; resultText = '';}">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <document-add
      :dialog="newDocumentsDialog"
      :rules="rules"
      v-on:save-document="saveDocuments"
      v-on:cancel="closeNewDocumentDialog">
    </document-add>
    
    <achievement-add
      :dialog="newAchievementDialog"
      :rules="rules"
      :isEvent="isEvent"
      v-on:save="saveAchievements"
      v-on:cancel="closeNewAchievementDialog"
    ></achievement-add>

    <modal-dialog
      :isVisible="isError"
      :title="errorTitle"
      :text="errorText"
      :rightButtonText="errorRightButtonText"
      :rightButtonAction="errorRightButtonAction"
      :leftButtonText="errorLeftButtonText"
      :leftButtonAction="errorLeftButtonAction"
    >
    </modal-dialog>
  </v-container>
</template>

<script>
//import testOrders from "../model/testOrders";

  import axios from "axios";
  import studentInfo from "../model/studentInfo";
  import inputRules from "../model/inputRules";
  import CustomModalDialog from '../components/ModalDialog.vue';

  import DocumentsVue from '../components/Documents.vue'
  import AchievementsVue from '../components/Achievements.vue';
  import EducationVue from '../components/Education.vue';
  import OrdersVue from '../components/Orders.vue';
  import CourseVue from '../components/Courses.vue';
  import ProfileVue from '../components/Profile.vue';
  import AchievementAddVue from '../components/AchievementAdd.vue';
  import DocumentAddVue from '../components/DocumentAdd.vue';
  
  export default {
    name: 'AccountView',
    data: () => ({
      token: null,
      isMacroExecuting: false,
      studentInfo: null,
      achievements: [],
      documents: [],
      orders: [],
      rules: { ...inputRules },

      isError: false,
      errorTitle: null,
      errorText: null,
      errorRightButtonText: null,
      errorRightButtonAction: null,
      errorLeftButtonText: null,
      errorLeftButtonAction: null,

      resultSnackbar: false,
      resultText: '',
      isResultSuccess: false,

      ordersTableLoading: false,
      newAchievementDialog: false,
      newDocumentsDialog: false,
    }),
    components: {
      'orders': OrdersVue,
      'profile': ProfileVue,
      'education': EducationVue,
      'courses' : CourseVue,
      'documents': DocumentsVue,
      'achievements': AchievementsVue,
      'modal-dialog': CustomModalDialog,
      'achievement-add': AchievementAddVue,
      'document-add' : DocumentAddVue,
    },
    methods: {
      getToken(){
        let tokenElements = document.getElementsByName("__RequestVerificationToken");
        if(tokenElements && tokenElements.length > 0)
          this.token = tokenElements[0].value;
      },
      isEvent(type) {
        return type === 'Победы';
      },
      openNewDocumentDialog(){
        if(this.$refs.documents) {
          this.$refs.documents.editingDocument = null;
          //this.$refs.documents.collapseAllPanels();
        }
        this.newDocumentsDialog = true;
      },
      closeNewDocumentDialog(){
        this.newDocumentsDialog = false;
      },
      openNewAchievementDialog() {
        if(this.$refs.achievements) {
          this.$refs.achievements.editingAchievement = null;
          this.$refs.achievements.collapseAllPanels();
        }
        this.newAchievementDialog = true;
      },
      closeNewAchievementDialog() {
        this.newAchievementDialog = false;
      },
      showSnackbar(success, text) {
        this.isResultSuccess = success;
        this.resultText = text;
        this.resultSnackbar = true;
      },
      setProcessing(state) {
        this.$emit('set-processing', state);
      },
      signOut() {
        window.location.href = "/Auth/SignOut";
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      },
      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split(".");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      formatStudentData(studentData) {
        if (studentData.snils && typeof studentData.snils === 'string')
          studentData.snils = studentData.snils ? studentData.snils.replaceAll(' ', '').replaceAll('-', '') : '';
        return studentData;
      },
      async saveProfile(studentData, type) {
        const studentDataFormated = this.formatStudentData(studentData);
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        try {
          let response = await axios.post('/do/api?macro=api_change_profile_settings', studentDataFormated);
          this.saveProfileResponse(response.data, type);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.saveProfileResponse(data, type);
        }
      },
      async saveProfileResponse(data, type) {
        if(data.success) {
          await this.getStudentInfo();

          if(type === 'profile') {
            this.$refs.profile.isGeneralEditing = false;
            this.$refs.profile.isContactsEditing = false;
            this.$refs.profile.isParentsEditing = false;
          }
          else if(type === 'education') {
            this.$refs.education.isGeneralEditing = false;
            this.$refs.education.isProfessionalEditing = false;
          }
          this.showSnackbar(true, 'Изменения успешно сохранены');
        }
        else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },
      async getStudentInfo() {
        this.setProcessing(true);
        try {
          let response = await axios.get("/api/getStudentInfo", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if(response.data.success === false) throw new Error("Ошибка загрузки пользователя");
          this.studentInfo = response.data;
        } 
        catch (error) {
          console.error(error);
          this.showCriticalError('Ошибка загрузки', 'При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки.');
        }
        finally {
          this.setProcessing(false);
        }
      },
      async getDocuments() {
        this.setProcessing(true);
        try {
          let response = await axios.get("/api/getDocuments", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if(response.data.success === false) throw new Error("Ошибка загрузки документов");
          this.documents = response.data;
          await this.$nextTick();
        } 
        catch (error) {
          console.error(error);
          this.showCriticalError('Ошибка загрузки', 'При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки.');
        }
        finally {
          this.setProcessing(false);
        }
      },
      async getAchievements() {
        this.setProcessing(true);
        try {
          let response = await axios.get("/api/getAchievements", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if(response.data.success === false) throw new Error("Ошибка загрузки достижений");
          this.achievements = response.data;
          await this.$nextTick();
        } 
        catch (error) {
          console.error(error);
          this.showCriticalError('Ошибка загрузки', 'При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки.');
        }
        finally {
          this.setProcessing(false);
        }
      },
      async getOrders() {
        this.ordersTableLoading = true;
        try {
          let response = await axios.get("/api/getOrders", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if(response.data.success === false) throw new Error("Ошибка загрузки заявок");
          this.orders = response.data;
          // Test objs
          //testOrders.forEach(to => this.orders.push(to));
        } 
        catch (error) {
          console.error(error);
          this.showCriticalError('Ошибка загрузки', 'При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки.');
        }
        finally {
          this.ordersTableLoading = false;
        }
      },

      async checkUserAuthenticate() {
        await this.$getUserAuthenticate();
        let isAuthenticated = this.$isAuthenticated;
        if(!isAuthenticated) {
          this.setProcessing(false);
          this.isError = true;
          this.errorTitle = 'Ошибка сохранения';
          this.errorText = 'Для продолжения работы необходимо авторизоваться в личном кабинете. Пожалуйста, перейдите на страницу авторизации.';
          this.errorRightButtonText = 'Перейти';
          this.errorRightButtonAction = this.reloadPage;

          return false;
        }
        else {
          return true;
        }
      },
      async saveDocuments(document, from){
        if(this.isMacroExecuting === true) return;
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        let documentsForSend = { ...document };

        let macroData = new FormData();
        macroData.append("macroName", "api_document_set");
        macroData.append("payload", JSON.stringify(documentsForSend));
        macroData.append("file", document.attach);

        try {
          this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token,
              'FormDataXHR': true
            },
          });
          response.data.document = {
            name: documentsForSend.name
          }
          this.saveDocumentResponse(response.data, from);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message //"Ошибка сохранения документа. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
          this.saveDocumentResponse(data, from);
        }
      },
      async saveDocumentResponse(data, from){
        this.isMacroExecuting = false;
        if(data.success) {
          await this.getDocuments();

          if(from === 'changeForm') {
            this.$refs.documents.editingDocument = null;
            this.showSnackbar(true, 'Изменения успешно сохранены');
          }
          else if(from === 'newForm') {
            await this.$nextTick();
            if(data.document && data.document.name) {
              const addedDocument = this.documents.find(a => {
                return a.name === data.document.name;
              });
              if(addedDocument && this.$refs.documents) {
                this.$refs.documents.$refs[addedDocument.id][0].panel = 0;
              }
            }
            this.newDocumentsDialog = false;
            this.showSnackbar(true, 'Документ успешно добавлен');
          }
        }
        else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },
      async saveAchievements(achievement, from) {
        if(this.isMacroExecuting === true) return;
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        let achievementForSend = { ...achievement };
        if(achievementForSend.year) {
          achievementForSend.year = achievement.year.toString() + '-01-01';
        }

        let macroData = new FormData();
        macroData.append("macroName", "api_achievement_set");
        macroData.append("payload", JSON.stringify(achievementForSend));
        if(achievement.attach) {
          macroData.append("file", achievement.attach);
        }
        try {
          this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token,
              'FormDataXHR': true
            },
          });
          response.data.achievement = {
            type: achievementForSend.type,
            name: achievementForSend.name
          }
          this.saveAchievementResponse(response.data, from);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: /*error.message*/ "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
          this.saveAchievementResponse(data, from);
        }
      },
      async saveAchievementResponse(data, from) {
        this.isMacroExecuting = false;
        if(data.success) {
          await this.getAchievements();

          if(from === 'changeForm') {
            this.$refs.achievements.editingAchievement = null;
            this.showSnackbar(true, 'Изменения успешно сохранены');
          }
          else if(from === 'newForm') {
            await this.$nextTick();
            if(data.achievement && data.achievement.name) {
              const addedAchievement = this.achievements.find(a => {
                return a.name === data.achievement.name && a.type === data.achievement.type;
              });
              if(addedAchievement && this.$refs.achievements) {
                this.$refs.achievements.$refs[addedAchievement.id][0].panel = 0;
              }
            }
            this.newAchievementDialog = false;
            this.showSnackbar(true, 'Достижение успешно добавлено');
          }
        }
        else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },

      async deleteAchievement(id) {
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        let achievementData = new FormData();
        achievementData.append("id", id);
        try {
          let response = await axios.post('/api/deleteAchievement', achievementData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.deleteAchievementResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.deleteAchievementResponse(data);
        }
      },
      async deleteAchievementResponse(data) {
        if(data.success) {
          this.$refs.achievements.editingAchievement = null;
          await this.getAchievements();
          this.showSnackbar(true, 'Достижение успешно удалено');
        }
        else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },
      async deleteDocument(id) {
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        let documentData = new FormData();
        documentData.append("id", id);
        try {
          let response = await axios.post('/api/deleteDocument', documentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.deleteDocumentResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.deleteDocumentResponse(data);
        }
      },
      async deleteDocumentResponse(data) {
        if(data.success) {
          this.$refs.documents.editingDocument = null;
          await this.getDocuments();
          this.showSnackbar(true, 'Докуент успешно удален');
        }
        else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },

      async deleteOrder(id) {
        this.ordersTableLoading = true;
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.ordersTableLoading = false;
          return;
        }
        let orderData = new FormData();
        orderData.append("id", id);
        try {
          let response = await axios.post('/api/deleteOrder', orderData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.deleteOrderResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.deleteOrderResponse(data);
        }
      },
      async deleteOrderResponse(data) {
        if(data.success) {
          if(this.$refs.orders && this.$refs.orders.openedOrder) {
            this.$refs.orders.closeOrder();
          }
          await this.getOrders();
          this.showSnackbar(true, 'Заявка успешно удалена');
        }
        else {
          this.ordersTableLoading = false;
          this.showSnackbar(false, data.message);
        }
      },

      async changeOrderState(order, statename) {
        if(this.isMacroExecuting === true) return;
        this.ordersTableLoading = true;
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.ordersTableLoading = false;
          return;
        }

        const orderData = {
          id: order.id,
          courseid: order.course.id,
          statename: statename
        };

        let macroData = new FormData();
        macroData.append("macroName", "api_request_set");
        macroData.append("payload", JSON.stringify(orderData));

        try {
          this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.changeOrderStateResponse(response.data, statename);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
          this.changeOrderStateResponse(data, statename);
        }
      },
      async changeOrderStateResponse(data, statename) {
        this.isMacroExecuting = false;
        if(data.success) {          
          await this.getOrders();
          await this.getAchievements();
          const message = statename === 'Новая' ? 'Заявка успешно подана на рассмотрение' : 'Заявка возвращена на редактирование';
          this.showSnackbar(true, message);
        }
        else {
          this.showSnackbar(false, data.message);
          this.ordersTableLoading = false;
        }
      },

      async selectOrderAchievement(achievement) {
        if(this.isMacroExecuting === true) return;
        this.ordersTableLoading = true;
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.ordersTableLoading = false;
          return;
        }

        const achievementData = {
          id: null,
          requestid: achievement.orderId,
          achievementid: achievement.id
        };

        let macroData = new FormData();
        macroData.append("macroName", "api_request_achievement_set");
        macroData.append("payload", JSON.stringify(achievementData));

        try {
          this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.selectOrderAchievementResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
          this.selectOrderAchievementResponse(data);
        }
      },
      async selectOrderAchievementResponse(data, statename) {
        this.isMacroExecuting = false;
        if(data.success) {
          await this.getOrders();
          await this.getAchievements();
        }
        else {
          this.showSnackbar(false, data.message);
          this.ordersTableLoading = false;
        }
      },
      async removeOrderAchievement(id, orderId) {
        this.ordersTableLoading = true;
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.ordersTableLoading = false;
          return;
        }
        let achievementData = new FormData();
        achievementData.append("id", id);
        achievementData.append("orderId", orderId);
        try {
          let response = await axios.post('/api/removeOrderAchievement', achievementData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.removeOrderAchievementResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.removeOrderAchievementResponse(data);
        }
      },
      async removeOrderAchievementResponse(data) {
        if(data.success) {
          await this.getOrders();
          await this.getAchievements();
        }
        else {
          this.ordersTableLoading = false;
          this.showSnackbar(false, data.message);
        }
      },
      async saveNewOrder(order) {
        if(this.isMacroExecuting === true) return;

        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.$refs.orders.$refs.newOrder.isLoading = false;
          return;
        }
        const orderData = {
          id: null,
          courseid: order.course.id,
          statename: null
        };

        let macroData = new FormData();
        macroData.append("macroName", "api_request_set");
        macroData.append("payload", JSON.stringify(orderData));

        try {
          this.isMacroExecuting = true;
          await this.$nextTick()
          let response = await axios.post('/api/callMacro', macroData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          let responseData = response.data;
          responseData.order = order;
          this.saveNewOrderResponse(responseData);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
          this.saveNewOrderResponse(data);
        }
        finally {
          this.$refs.orders.$refs.newOrder.isLoading = false;
        }
      },
      async saveNewOrderResponse(data) {
        this.isMacroExecuting = false;
        if(data.success) {
          this.$refs.orders.isOrderCreating = false;
          await this.getOrders();
          const message = 'Заявка успешно создана. Пожалуйста, приложите подходящие достижения и документы. Далее отправьте заявку на рассмотрение.';
          this.showSnackbar(true, message);
          const orderToOpen = this.orders.filter(o => o.course.id === data.order.course.id)[0];
          this.$refs.orders.openOrder(orderToOpen);
        }
        else {
          this.showSnackbar(false, data.message);
          this.$refs.orders.$refs.newOrder.isLoading = false;
        }
      },

      //===========
      async selectOrderDocument(document) {
        if(this.isMacroExecuting === true) return;
        this.ordersTableLoading = true;
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.ordersTableLoading = false;
          return;
        }

        const documentData = {
          id: null,
          requestid: document.orderId,
          documentid: document.id
        };

        let macroData = new FormData();
        macroData.append("macroName", "api_request_document_set");
        macroData.append("payload", JSON.stringify(documentData));

        try {
          this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.selectOrderDocumentResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
          this.selectOrderDocumentResponse(data);
        }
      },
      async selectOrderDocumentResponse(data) {
        this.isMacroExecuting = false;
        if(data.success) {
          await this.getOrders();
          await this.getDocuments();
        }
        else {
          this.showSnackbar(false, data.message);
          this.ordersTableLoading = false;
        }
      },
      async removeOrderDocument(id, orderId) {
        this.ordersTableLoading = true;
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.ordersTableLoading = false;
          return;
        }
        let documentData = new FormData();
        documentData.append("id", id);
        documentData.append("orderId", orderId);
        try {
          let response = await axios.post('/api/removeOrderDocument', documentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.removeOrderDocumentResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.removeOrderDocumentResponse(data);
        }
      },
      async removeOrderDocumentResponse(data) {
        if(data.success) {
          await this.getOrders();
          await this.getDocuments();
        }
        else {
          this.ordersTableLoading = false;
          this.showSnackbar(false, data.message);
        }
      },
      //===========


      async changePassword(newPassword) {
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
          const profileData = {
          profile: {
              name : this.studentInfo.login,
              email : this.studentInfo.contacts.email,
            },
          password: newPassword
          };
        let macroData = new FormData();
          macroData.append("profile", JSON.stringify(profileData.profile));
          macroData.append("password", profileData.password);
        try {
          let response = await axios.post('/security/updateuseroptions', macroData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-Requested-With': 'XMLHttpRequest'
            }
          });
          this.saveChangePasswordResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.saveChangePasswordResponse(data);
        }
      },
      async saveChangePasswordResponse(data) {
        this.setProcessing(false);
        if(data.success) 
          this.showSnackbar(true, 'Изменения успешно сохранены');
        else 
          this.showSnackbar(false, "Ошибка смены пароля");
      },
      showCriticalError(errorTitle, errorText) {
        this.isError = true;
        this.errorTitle = errorTitle;
        this.errorText = errorText;
        this.errorRightButtonText = 'Обновить';
        this.errorRightButtonAction = this.reloadPage;
        this.errorLeftButtonText = 'Выйти';
        this.errorLeftButtonAction = this.signOut;
      },
      reloadPage() {
        window.location.reload();
      }
    },
    created() {
      this.getDocuments();
      this.getToken();
      this.getStudentInfo();
      this.getAchievements();
      this.getOrders();
    },
  }
</script>

<style lang="scss">
  body { 
    scroll-behavior: smooth; 
  }
  .font-weight-half-medium {
    font-weight: 400 !important;
  }
</style>
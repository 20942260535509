export default {
  required: (value) => !!value || "Обязательное поле",
  email: (value) => {
    if (value) {
      const pattern = /^(([^|А-ЯЁ|а-яё<>/()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Некорректный e-mail";
    } else {
      return true;
    }
  },
  passwordMatch: (password, repeated) => {
    return password === repeated || "Пароли не совпадают";
  },
  emailMatch: (email, repeated) => {
    return email === repeated || "Почта не совпадает";
  },
  emailCorrect: (email) => {
    if (/(gmail\.ru)/.test(email)) return "Возможно, вы имели в виду \"gmail.com\"";
    if (/(gmil)/.test(email)) return "Возможно, вы имели в виду \"gmail\"";
    return true;
  },
  minLogin: (value) => value && value.length >= 2 || "Минимум 2 символа",
  minPassword: (value) => value && value.length >= 6 || "Минимум 6 символов",
  phone: (value) => value && value.length === 16 || "Некорректный формат",
  file: (value) => {
    if (value) return value.size < 3000000 || 'Размер вложения не должен быть больше 3 MB'
    else return true;
  },


  snils: (value) => {
    if (typeof value === 'number')
      value = value.toString();
    else if (typeof value !== 'string')
      value = '';
    
    value = value.replaceAll('-', '').replaceAll(' ', '')
    if (!value.length) return 'Укажите числа СНИЛС без пробелов';
    else if (/[^0-9]/.test(value)) 
      return 'СНИЛС может состоять только из цифр';
    else if (value.length !== 11)
      return 'СНИЛС может состоять только из 11 цифр';
    else if (checkSnilsControlSum(value) !== parseInt(value.slice(-2))) 		
      return 'Неправильное контрольное число СНИЛС';
    return true;
  },


  staffValidFileExtentions: [".jpg", ".jpeg", ".png", ".pdf", ".zip"], 
  staffFileFormat: (value) => {
    if (!value) return true;
    const staffValidFileExtentions = [".jpg", ".jpeg", ".png", ".pdf", ".zip"];  
    const splitedFileName = value.name.split('.');
    const fileType = `.${splitedFileName[splitedFileName.length - 1]}`;
    if (staffValidFileExtentions.includes(fileType.toLowerCase())) return true; 
    return `Допустимые расширения файла: ${staffValidFileExtentions.join(', ')}`;
  }
}

function checkSnilsControlSum(snils) {
  var sum = 0;
  for (var i = 0; i < 9; i++) {
    sum += parseInt(snils[i]) * (9 - i);
  }
  let checkDigit = 0;
  if (sum < 100) {
    checkDigit = sum;
  } else if (sum > 101) {
    checkDigit = parseInt(sum % 101);
    if (checkDigit === 100) {
      checkDigit = 0;
    }
  }
  return checkDigit;
}
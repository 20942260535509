<template>
  <div>
    <div class="text-center pb-4">
      <p v-if="selectedDocuments.length === 0" class="font-weight-light">
        Документы не приложены
      </p>
      <v-chip
        v-for="document in selectedDocumentsSorted"
        :key="document.id"
        :style="{ maxWidth: '350px' }"
        small
        :close="order.statename === 'Черновик'"
        outlined
        color="#00AEBA"
        class="ma-2"
        @click:close="removeDocument(document)"
        @click="onDocumentClicked(document)"
      >
        <v-icon size="18" left>mdi-file-document-outline</v-icon>
        <span :title="document.name" class="chip-text">{{document.name}}</span>
      </v-chip>
    </div>
    <div v-if="order.statename === 'Черновик'" class="text-center">
      <v-autocomplete
        v-model="documentSearch"
        :disabled="ordersTableLoading"
        label="Документ"
        hint="Выберите документ"
        placeholder="Выберите документ из списка"
        color="#af955c"
        no-data-text="Нет доступных документов"
        dense
        :items="availableDocuments"
        item-text="name"
        return-object
        @change="onDocumentClicked"
      >
        <template v-slot:item="data">
            <v-list-item-avatar>
              <v-icon color="#00AEBA">mdi-file-document-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
        </template>
        <template v-slot:append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="#af955c"
                @click="createNewDocument"
                v-bind="attrs"
                v-on="on"
              >mdi-plus-circle-outline</v-icon>
            </template>
            <span>Создать документ</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </div>
    <document-preview 
      :isVisible="isDocumentPreviewOpened" 
      :document="openedDocument"
      :selectedDocuments="selectedDocuments"
      v-on:select-document="selectDocument"
      v-on:close="closeDocumentPreview"
    />
  </div>
</template>

<script>
import OrderDocumentPreviewVue from './OrderDocumentPreview.vue';
export default {
  name: "OrderDocuments",
  props: {
    order: Object,
    documents: Array,
    ordersTableLoading: Boolean
  },
  data() {
    return {
      selectedDocuments: [],
      documentSearch: null,
      isDocumentPreviewOpened: false,
      openedDocument: null
    }
  },
  components: {
    'document-preview': OrderDocumentPreviewVue
  },
  computed: {
    availableDocuments() {
      return this.documents.filter(document => {
        return this.selectedDocuments.filter(selected => selected.id === document.id).length === 0;
      });
    },
    selectedDocumentsSorted() {
      let resultArray = this.selectedDocuments.sort((a, b) => {
        return a.name < b.name
      });
      return resultArray;
    },
    orderStringify() {
      return JSON.stringify(this.order);
    }
  },
  methods: {
    async onDocumentClicked(document) {
      let slectedDocument = this.documents.filter(d => d.id === document.id);
      if(slectedDocument?.length > 0) {
        await this.$nextTick();
        this.documentSearch = null;
        this.openedDocument = slectedDocument[0];
        this.isDocumentPreviewOpened = true;
      }
    },
    closeDocumentPreview() {
      this.openedDocument = null;
      this.isDocumentPreviewOpened = false;
    },
    createNewDocument() {
      this.$emit('add-document');
    },
    async selectDocument(document) {
      let selected = {
        id: document.id,
        name: document.name,
        orderId: this.order.id
      };
      this.$emit('select-document', selected);
      this.documentSearch = null;
      this.isDocumentPreviewOpened = false;
      this.openedDocument = null;
    },
    async removeDocument(document) {
      if(!this.ordersTableLoading) {
        this.$emit('remove-document', document.id, this.order.id);
      }
    },
    setSelectedDocuments() {
      this.selectedDocuments = [ ...this.order.documents ];
    }
  },
  watch: {
    orderStringify: {
      handler(val, oldVal) {
        this.setSelectedDocuments();
      }
    }
  },
  created() {
    this.setSelectedDocuments();
  }
};
</script>

<style lang="scss">
  .chip-text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
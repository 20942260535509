<template>
    <v-container>
      <keep-alive>
        <camps
          ref="camps"
          :camps="camps"
          v-if="$route.path === '/quals/camps'"
          v-on:set-processing="setProcessing"
        >
        </camps>
        <profile
          ref="profile"
          v-if="$route.path === '/quals/profile'"
          :qualsInfo="qualsInfo"
          :rules="rules"
          :formatDate="formatDate"
          :parseDate="parseDate"
          :campShifts="campShifts"
          :positions="positions"
          v-on:get-camp-shifts="getCampShifts"
          v-on:get-positions="getPositions"
          v-on:set-processing="setProcessing"
          v-on:save="saveProfile"
          v-on:change-password="changePassword"
        ></profile>
        <documents
          ref="documents"
          :documents="documents"
          :rules="rules"
          v-if="$route.path === '/quals/documents'"
          v-on:set-processing="setProcessing"
          v-on:save="saveDocuments"
          v-on:add-document="openNewDocumentDialog"
          v-on:delete-document="deleteDocument"
          v-on:show-snackbar="showSnackbar"
          v-on:get-documents="getDocuments"
        ></documents>
      </keep-alive>
  
      <v-snackbar
        v-model="resultSnackbar"
        class="text-center"
        vertical
        multi-line
        timeout="7000"
        :color="isResultSuccess ? '#af955c' : ''"
        :outlined="isResultSuccess"
      >
        <span class="text-center" v-html="resultText"></span>
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="isResultSuccess ? '#af955c' : '#e31e25'"
            text
            v-bind="attrs"
            @click="
              () => {
                resultSnackbar = false;
                resultText = '';
              }
            "
          >
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
      <document-add
        :dialog="newDocumentsDialog"
        :rules="rules"
        v-on:save-document="saveDocuments"
        v-on:cancel="closeNewDocumentDialog"
      >
      </document-add>
  
      <modal-dialog
        :isVisible="isError"
        :title="errorTitle"
        :text="errorText"
        :rightButtonText="errorRightButtonText"
        :rightButtonAction="errorRightButtonAction"
        :leftButtonText="errorLeftButtonText"
        :leftButtonAction="errorLeftButtonAction"
      >
      </modal-dialog>
    </v-container>
  </template>
  
  <script>
  //import testOrders from "../model/testOrders";
  import axios from "axios";
  import qualsInfo from "../../model/qualsInfo";
  import inputRules from "../../model/inputRules";
  import CustomModalDialog from "../../components/ModalDialog.vue";
  
  import DocumentsVue from "../../components/quals/Documents.vue";
  import CampsVue from "../../components/quals/Camps.vue";
  import ProfileVue from "../../components/quals/Profile.vue";
  import DocumentAddVue from "../../components/quals/DocumentAdd.vue";
  
  export default {
    name: "AccountView",
    data: () => ({
      token: null,    
      qualsInfo: null,
      documents: [],
      positions: [],
      campShifts: [],
      camps: [],
  
      rules: { ...inputRules },
      
      isMacroExecuting: false,
      isError: false,
      errorTitle: null,
      errorText: null,
      errorRightButtonText: null,
      errorRightButtonAction: null,
      errorLeftButtonText: null,
      errorLeftButtonAction: null,
  
      resultSnackbar: false,
      resultText: "",
      isResultSuccess: false,
  
      newDocumentsDialog: false,
    }),
    components: {
      profile: ProfileVue,
      camps: CampsVue,
      documents: DocumentsVue,
      "modal-dialog": CustomModalDialog,
      "document-add": DocumentAddVue,
    },
    methods: {
      getToken() {
        let tokenElements = document.getElementsByName(
          "__RequestVerificationToken"
        );
        if (tokenElements && tokenElements.length > 0)
          this.token = tokenElements[0].value;
      },
      isEvent(type) {
        return type === "Победы";
      },
      openNewDocumentDialog() {
        if (this.$refs.documents) {
          this.$refs.documents.editingDocument = null;
          //this.$refs.documents.collapseAllPanels();
        }
        this.newDocumentsDialog = true;
      },
      closeNewDocumentDialog() {
        this.newDocumentsDialog = false;
      },
  
      showSnackbar(success, text) {
        this.isResultSuccess = success;
        this.resultText = text;
        this.resultSnackbar = true;
      },
      setProcessing(state) {
        this.$emit("set-processing", state);
      },
      signOut() {
        window.location.href = "/Auth/SignOut";
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      },
      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split(".");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      async getCampShift() {
        try {
          let response = await axios.get("/api/getqualscampshift", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.campShifts = response.data;
        } catch (error) {
          this.campShifts = [];
        }
      },
      async getCamps(){
        try {
          let response = await axios.get("/api/getqualscamps", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.camps = response.data;
        } catch (error) {
          this.camps = [];
        }
      },
      async getCampPositions() {
        try {
          let response = await axios.get("/api/getqualspositions", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.positions = response.data;
        } catch (error) {
          this.positions = [];
        }
      },
      async saveProfile(qualsInfo, type) {
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if (isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        try {
          let response = await axios.post(
            "/do/api?macro=api_change_quals_profile_settings",
            qualsInfo
          );
          this.saveProfileResponse(response.data, type);
        } catch (error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message,
          };
          this.saveProfileResponse(data, type);
        }
      },
      async saveProfileResponse(data, type) {
        if (data.success) {
          await this.getQualsInfo();
  
          if (type === "profile") {
            this.$refs.profile.isGeneralEditing = false;
            this.$refs.profile.isContactsEditing = false;
            this.$refs.profile.isEducationEditing = false;
            this.$refs.profile.isWorkInformationEditing = false;
            this.$refs.profile.isChangingPassword = false;
          }
          this.showSnackbar(true, "Изменения успешно сохранены");
        } else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },
      async getQualsInfo() {
        this.setProcessing(true);
        try {
          let response = await axios.get("/api/getQualsInfo", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if (response.data.success === false)
            throw new Error("Ошибка загрузки пользователя");
          this.qualsInfo = response.data;
        } catch (error) {
          console.error(error);
          this.showCriticalError(
            "Ошибка загрузки",
            "При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки."
          );
        } finally {
          this.setProcessing(false);
        }
      },
      async getDocuments() {
        this.setProcessing(true);
        try {
          let response = await axios.get("/api/getQualsDocuments", {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if (response.data.success === false)
            throw new Error("Ошибка загрузки документов");
          this.documents = response.data;
          await this.$nextTick();
        } catch (error) {
          console.error(error);
          this.showCriticalError(
            "Ошибка загрузки",
            "При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки."
          );
        } finally {
          this.setProcessing(false);
        }
      },
  
      async checkUserAuthenticate() {
        await this.$getUserAuthenticate();
        let isAuthenticated = this.$isAuthenticated;
        if (!isAuthenticated) {
          this.setProcessing(false);
          this.isError = true;
          this.errorTitle = "Ошибка сохранения";
          this.errorText =
            "Для продолжения работы необходимо авторизоваться в личном кабинете. Пожалуйста, перейдите на страницу авторизации.";
          this.errorRightButtonText = "Перейти";
          this.errorRightButtonAction = this.reloadPage;
  
          return false;
        } else {
          return true;
        }
      },
      async saveDocuments(document, from) {
        if (this.isMacroExecuting === true) return;
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if (isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        let documentsForSend = { ...document };
  
        let macroData = new FormData();
        macroData.append("macroName", "api_quals_document_set");
        macroData.append("payload", JSON.stringify(documentsForSend));
        macroData.append("file", document.attach);
  
        try {
          this.isMacroExecuting = true;
          let response = await axios.post("/api/callMacro", macroData, {
            headers: {
              "Content-Type": "multipart/form-data",
              RequestVerificationToken: this.token,
              FormDataXHR: true,
            },
          });
          response.data.document = {
            name: documentsForSend.name,
          };
          this.saveDocumentResponse(response.data, from);
        } catch (error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message, //"Ошибка сохранения документа. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          };
          this.saveDocumentResponse(data, from);
        }
      },
      async saveDocumentResponse(data, from) {
        this.isMacroExecuting = false;
        if (data.success) {
          await this.getDocuments();
  
          if (from === "changeForm") {
            this.$refs.documents.editingDocument = null;
            this.showSnackbar(true, "Изменения успешно сохранены");
          } else if (from === "newForm") {
            await this.$nextTick();
            if (data.document && data.document.name) {
              const addedDocument = this.documents.find((a) => {
                return a.name === data.document.name;
              });
              if (addedDocument && this.$refs.documents) {
                this.$refs.documents.$refs[addedDocument.id][0].panel = 0;
              }
            }
            this.newDocumentsDialog = false;
            this.showSnackbar(true, "Документ успешно добавлен");
          }
        } else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },
  
      async deleteDocument(id) {
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        let documentData = new FormData();
        documentData.append("id", id);
        try {
          let response = await axios.post('/api/deleteQualsDocument', documentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
          this.deleteDocumentResponse(response.data);
        }
        catch(error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message
          }
          this.deleteDocumentResponse(data);
        }
      },
      async deleteDocumentResponse(data) {
        if (data.success) {
          this.$refs.documents.editingDocument = null;
          await this.getDocuments();
          this.showSnackbar(true, "Докуент успешно удален");
        } else {
          this.setProcessing(false);
          this.showSnackbar(false, data.message);
        }
      },
  
      async changePassword(newPassword) {
        this.setProcessing(true);
        const isAuth = await this.checkUserAuthenticate();
        if (isAuth !== true) {
          this.setProcessing(false);
          return;
        }
        const profileData = {
          profile: {
            name: this.qualsInfo.login,
            email: this.qualsInfo.contacts.email,
          },
          password: newPassword,
        };
        let macroData = new FormData();
        macroData.append("profile", JSON.stringify(profileData.profile));
        macroData.append("password", profileData.password);
        try {
          let response = await axios.post(
            "/security/updateuseroptions",
            macroData,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          this.saveChangePasswordResponse(response.data);
        } catch (error) {
          console.error(error);
          var data = {
            success: false,
            message: error.message,
          };
          this.saveChangePasswordResponse(data);
        }
      },
      async saveChangePasswordResponse(data) {
        this.setProcessing(false);
        if (data.success) this.showSnackbar(true, "Изменения успешно сохранены");
        else this.showSnackbar(false, "Ошибка смены пароля");
      },
      showCriticalError(errorTitle, errorText) {
        this.isError = true;
        this.errorTitle = errorTitle;
        this.errorText = errorText;
        this.errorRightButtonText = "Обновить";
        this.errorRightButtonAction = this.reloadPage;
        this.errorLeftButtonText = "Выйти";
        this.errorLeftButtonAction = this.signOut;
      },
      reloadPage() {
        window.location.reload();
      },
    },
    created() {
      this.getCampPositions();
      this.getCamps();
      this.getCampShift();
      this.getDocuments();
      this.getToken();
      this.getQualsInfo();
    },
  };
  </script>
  
  <style lang="scss">
  body {
    scroll-behavior: smooth;
  }
  .font-weight-half-medium {
    font-weight: 400 !important;
  }
  </style>
  
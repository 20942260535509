<template>
  <v-form class="mx-5" v-model="isDocumentValid">
    <v-row class="mt-4" justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-select
          v-model="documentForEdit.type"
          label="Тип документа"
          hint="Укажите тип документа"
          color="#af955c"
          dense
          :items="documentTypes"
          :rules="[rules.required]"
          :error="!documentForEdit.type"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="documentForEdit.type && documentDataFields[documentForEdit.type]" class="mt-4" justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          v-model="documentForEdit.name"
          :label="documentDataFields[documentForEdit.type][0]"
          :hint="documentDataFields[documentForEdit.type][1]"
          color="#af955c"
          dense
          required
          :rules="[rules.required]"
          :error="!documentForEdit.name"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <attachment :attach="attachment"> </attachment>
    
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-file-input
          show-size
          clearable
          counter
          color="#af955c"
          :accept="rules.qualsValidFileExtentions.join(', ')"
          dense
          label="Вложение"
          :placeholder="
            !documentForEdit.attach && document.attach ? document.attach.name : '' "
          :rules="[rules.file, rules.qualsFileFormat]"
          v-on:change="onFileChanged"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row v-if="!isNew" class="mt-4" justify="center" align="center">
      <v-btn
        v-if="!isNew"
        color="#e31e25"
        class="ma-2"
        text
        dark
        small
        @click="deleteDocument"
      >
        <v-icon left dark> mdi-window-close </v-icon>
        Удалить документ
      </v-btn>
    </v-row>
    <v-row class="pt-3 pb-1" justify="center" align="center">
      <v-btn
        color="#af955c"
        text
        :disabled="!isDocumentValid"
        :dark="isDocumentValid"
        @click="saveDocument"
      >
        <v-icon left dark>mdi-content-save-outline</v-icon>
        Сохранить
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import AttachmentVue from "./Attachment.vue";

export default {
  name: "DocumentForm",
  props: {
    isNew: Boolean,
    availableDocumentTypes: Array,
    document: Object,
    rules: Object,
  },
  data() {
    return {
      documentTypes: [
        'Паспорт',
        'Банковские реквизиты',
        'Военный билет',
        'Документы об образовании',
        'ИНН',
        'Личная медицинская книжка',
        'Медицинский полис',
        'Прививочный сертификат',
        'СНИЛС',
        'Справка об отсутствии судимости',
        'Иные документы',
      ],
      documentDataFields: {
        'Паспорт': ['Номер и серия паспорта', 'Введите номер и серию паспорта через пробел'],
        'Банковские реквизиты': ['Номер счёта', 'Введите номер счёта'],
        'Военный билет': ['Номер билета', 'Введите номер билета'],
        'Документы об образовании': ['Номер документа', 'Введите номер документа'],
        'ИНН': ['ИНН', 'Введите ИНН'],
        'Личная медицинская книжка': ['Номер медицинской книжки', 'Введите номер медицинской книжки'],
        'Медицинский полис': ['Номер полиса', 'Введите номер полиса'],
        'Прививочный сертификат': ['Номер прививочного сертификата', 'Введите номер прививочного сертификата'],
        'СНИЛС': ['Номер счёта', 'Введите номер счёта'],
        'Справка об отсутствии судимости': ['Номер справки', 'Введите номер справки'],
        'Иные документы': ['Номер документа', 'Введите номер документа'],
      },
      documentForEdit: {
        type: null,
        name: null,
        data: {
          field1: null,
          field2: null,
        },
      },
      attachment: null,
      isDocumentValid: false,
    }
  },
  components: {
    attachment: AttachmentVue,
  },
  computed: {
    documentStringify() {
      return JSON.stringify(this.document);
    },
    documentForEditStringify() {
      return JSON.stringify(this.documentForEdit);
    },
  },
  methods: {
    async getAttachment() {
      if (this.documentForEdit.attach) {
        const file = this.documentForEdit.attach;
        let bytes = await this.convertToBytes(file).catch((e) => Error(e));
        if (bytes instanceof Error) {
          this.$emit(
            "show-snackbar",
            false,
            "Ошибка загрузки файла, пожалуйста, попробуйте еще раз."
          );
          return;
        }
        let attach = {
          name: file.name,
          data: bytes.replace(/^data:.+;base64,/, ""),
        };
        this.attachment = attach;
      } else {
        this.attachment = this.document.attach;
      }
    },
    async onFileChanged(file) {
      this.documentForEdit.attach = file;
      await this.$nextTick();
      this.getAttachment();
    },
    convertToBytes(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    setDocumentForEdit() {
      if (this.document) {
        this.documentForEdit = JSON.parse(JSON.stringify(this.document));
        this.documentForEdit.attach = null;
      }
    },
    saveDocument() {
      this.$emit("save-document", this.documentForEdit);
    },
    deleteDocument() {
      this.$emit("delete-document", this.document.id);
    },
  },
  watch: {
    documentStringify: {
      handler(val, oldVal) {
        this.setDocumentForEdit();
      },
    },
    documentForEditStringify: {
      handler(val, oldVal) {
        // const newDocument = JSON.parse(val);
        // const oldocument = JSON.parse(oldVal);
      },
    },
  },
  created() {
    this.setDocumentForEdit();
    this.getAttachment();
  },
  mounted(){

  },
};
</script>
<style scoped></style>
